<template>
    <div class="col-lg-12 vld-parent">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-lg-8">
                <div class="checkout-wrapper">

                    <div id="cita" v-if="active" class="panel-group">

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title"><span>1.</span> <a data-toggle="collapse" data-parent="#cita" href="#payment-1">Elige la fecha y hora</a></h5>
                            </div>
                            <div id="payment-1" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Fecha</label>
                                            <div class="tax-select">
                                                <datepicker v-model="date" :format="dateFormat"
                                                    :disabledDates="disabledDates" @closed="getTimes()"
                                                    :language="es" :monday-first="true"
                                                    placeholder="seleciona una fecha" :inline="false" name="fecha">
                                                </datepicker>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label>Hora</label>
                                            <div class="tax-select">
                                                <div v-if="closed" class="alert alert-primary" role="alert" id="closed">
                                                    Parece que cerraremos temprano este día o ya no tenemos
                                                    lugares disponibles, por favor selecciona otra fecha
                                                </div>
                                                <div class="form-group">
                                                    <select v-if="!closed" class="s-wid" id="time" name="time"
                                                        v-model="time" required>
                                                        <option :value="null" disabled>Selecciona una hora
                                                        </option>
                                                        <option v-for="time in times" :key="time.id"
                                                            v-text="time.name" v-bind:value="time.id"></option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="showAddressCustomer" class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title"><span>2.</span> <a data-toggle="collapse" data-parent="#cita" href="#payment-2">Elige la dirección de la cita</a></h5>
                            </div>
                            <div id="payment-2" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <div class="order-review-wrapper">
                                        <div class="order-review">

                                            <div class="row">
                                                <div class="col-md-8">
                                                    <label>Mis direcciones</label>
                                                    <div class="tax-select">
                                                        <div class="form-group">
                                                            <select class="s-wid" id="address_id" name="address_id" v-model="appointment.address" required>
                                                                <option :value='{id:0,name:"",street:"",outdoorNumber:"",interiorNumber:"",postalCode:"",colony:"",betweenStreet:""}'>Selecciona una dirección
                                                                </option>
                                                                <option v-for="address in allAddressCustomer" :key="address.id" v-text="address.name" v-bind:value="address">
                                                                {{ address.name }}
                                                            </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group mt-20">
                                                        <div class="pull-right billing-btn">
                                                            <button type="button" v-on:click="showFormNewAddress()">Nueva dirección</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="showNewAddressCustomer">
                                                <hr>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p class="text-danger">{{ errorNewAddress }}</p>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label for="name">Nombre de la dirección (*)</label>
                                                        <input type="text" name="name" id="name" class="form-control"  v-model="newAddress.name">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label for="street">Calle (*)</label>
                                                        <input type="text" name="street" id="street"class="form-control" v-model="newAddress.street">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label for="outdoorNumber">Número ext. (*)</label>
                                                        <input type="text" name="outdoorNumber" id="outdoorNumber" class="form-control" v-model="newAddress.outdoorNumber">
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label for="interiorNumber">Número int.</label>
                                                        <input type="text" name="interiorNumber" id="interiorNumber"class="form-control" v-model="newAddress.interiorNumber">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 form-group">
                                                        <label for="postalCode">Código postal (*)</label>
                                                        <input type="text" name="postalCode" id="postalCode" class="form-control" v-model="newAddress.postalCode">
                                                    </div>
                                                    <div class="col-md-6 form-group">
                                                        <label for="colony">Colonia (*)</label>
                                                        <input type="text" name="colony" id="colony"class="form-control" v-model="newAddress.colony">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group">
                                                        <label for="betweenStreet">Entre calles (*)</label>
                                                        <input type="text" name="betweenStreet" id="betweenStreet"class="form-control" v-model="newAddress.betweenStreet">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12 form-group mt-20">
                                                        <div class="pull-right billing-btn">
                                                            <button type="button" v-on:click="hideFormNewAddress()" >Cerrar</button>
                                                            <button type="button" v-on:click="saveAddress()" >Guardar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title"><span>{{ showAddressCustomer ? "3." : "2." }}</span> <a data-toggle="collapse" data-parent="#cita" href="#payment-3">RESUMEN DE LA CITA</a></h5>
                            </div>
                            <div id="payment-3" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <div class="order-review-wrapper">
                                        <div class="order-review">

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p class="text-uppercase">
                                                        <strong>LUGAR:</strong> {{ appointment.address.name }} <br>
                                                        <strong>DIRECCIÓN:</strong> {{ appointment.address.street }} {{ appointment.address.outdoorNumber }} {{ appointment.address.interiorNumber }} {{ appointment.address.colony }} {{ appointment.address.postalCode }} {{ appointment.address.betweenStreet }}
                                                    </p>
                                                </div>
                                            </div>

                                            <div class="row mt-20">
                                                <div class="col-md-12">
                                                    <label for="phoneNumber">Teléfono de contacto (*)</label>
                                                    <input type="text" name="phoneNumber" id="phoneNumber" class="form-control" v-model="phoneNumber">
                                                </div>
                                            </div>


                                            <div class="row mt-20">
                                                <div class="col-md-12">
                                                    <p><strong>SERVICIOS</strong></p>
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <thead>
                                                                <tr>
                                                                    <th class="width-1">Servicio</th>
                                                                    <th class="width-3">Precio</th>
                                                                    <th class="width-3"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(service, index) in cartServices" :key="service.id">
                                                                    <td>
                                                                        <div class="o-pro-dec">
                                                                            <p class="text-uppercase">{{ service.name }}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="o-pro-price">
                                                                            <p>${{ service.price }}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="o-pro-price">
                                                                            <button type="button" class="btn btn-default" @click="removeService(index)"><i class="fa fa-trash"></i></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr v-if="fidelity > 0">
                                                                    <td colspan="2"><strong>Fidelidad</strong></td>
                                                                    <td class="text-center" colspan="1">- ${{ fidelity.toFixed(2) }}</td>
                                                                </tr>
                                                                <tr v-if="typeAppointment ==='INDOMICILIARY' || typeAppointment ==='PREBOOKING'" class="tr-f">
                                                                    <td colspan="2"><strong>COSTO A DOMICILIO</strong></td>
                                                                    <td class="text-center" colspan="1">$120.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colspan="2"><strong>TOTAL</strong></td>
                                                                    <td class="text-center" colspan="1">${{ granTotal.toFixed(2) }}</td>
                                                                </tr>
                                                                <tr v-if="typeBranch ==='KN' && typeAppointment ==='INBRANCH'" class="tr-f">
                                                                    <td colspan="2"><strong>PAGO PARA AGENDAR (50%)</strong></td>
                                                                    <td class="text-center" colspan="1">${{ total.toFixed(2) }}</td>
                                                                </tr>
                                                                <tr v-if="typeAppointment ==='INDOMICILIARY'" class="tr-f">
                                                                    <td colspan="2"><strong>PAGO PARA AGENDAR A DOMICILIO(70%)</strong></td>
                                                                    <td class="text-center" colspan="1">${{ total.toFixed(2) }}</td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="row mt-20">
                                                <div class="col-md-12">
                                                    <p class="text-danger">{{ errorPhone }}</p>
                                                </div>
                                            </div>
                                            
                                            <div class="billing-back-btn">
                                                <span>
                                                    ¿Olvidó un servicio?
                                                    <a v-if="typeAppointment ==='INBRANCH'" href="/menu">Volver al Menú</a>
                                                    <a v-if="typeAppointment ==='PREBOOKING'" href="/menu/prebooking">Volver al Menú</a>
                                                    <a v-if="typeAppointment ==='INDOMICILIARY'" href="/menu/indomiciliary">Volver al Menú</a>
                                                </span>
                                                <div v-if="appointment.address.name != '' && time" class="billing-btn">
                                                    <button v-if="typeAppointment ==='PREBOOKING' && granTotal >= 500" type="submit" @click="appointmentStore()">Pre-Agendar</button>
                                                    <button v-if="typeAppointment ==='INDOMICILIARY' && granTotal >= 500" type="submit" @click="appointmentStore()">Pagar</button>
                                                    <button v-if="typeAppointment ==='INBRANCH'" type="submit" @click="appointmentStore()">{{ typeBranch === 'KN' ? 'Pagar' : 'Agendar' }}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-else class="alert alert-light" role="alert">
                        <h4 class="alert-heading"> El carrito de Agenda esta vacío!</h4>
                        <p>
                            Para agendar una cita, es necesario ir al MENÚ y agregar los servicios deseados al carrito de agenda.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="checkout-wrapper">

                    <div id="info" v-if="active" class="panel-group">

                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title">
                                    <a data-toggle="collapse" data-parent="#info" href="#info-pay">Información</a>
                                </h5>
                            </div>
                            <div id="info-pay" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <img v-if="typeAppointment ==='INBRANCH'" class="img-fluid" src="/img/agenda.jpeg" alt="img">
                                            <img v-if="typeAppointment ==='PREBOOKING'" class="img-fluid" src="/img/preebooking.png" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="typeAppointment ==='PREBOOKING'" class="panel panel-default">
                            <div class="panel-heading">
                                <h5 class="panel-title">
                                    <a data-toggle="collapse" data-parent="#info" href="#info-policy">Políticas</a>
                                </h5>
                            </div>
                            <div id="info-policy" class="panel-collapse collapse show">
                                <div class="panel-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <img class="img-fluid" src="/img/prebooking-policy.png" alt="img">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Datepicker from 'vuejs-datepicker';
    import { es } from "vuejs-datepicker/dist/locale";
    import Multiselect from 'vue-multiselect';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {

        components: {
            Datepicker,
            Multiselect,
            Loading
         },

        data: function() {
            return {
                errorNewAddress:'',
                errorPhone:'',
                typeAppointment:"",
                appointment:{
                    address:{
                        id:null,
                        name:"",
                        street:"",
                        outdoorNumber:"",
                        interiorNumber:"",
                        postalCode:"",
                        colony:"",
                        betweenStreet:""
                    }
                },
                allAddressCustomer:[],
                showAddressCustomer:false,
                showNewAddressCustomer:false,
                newAddress:{
                    name:"",
                    street:"",
                    outdoorNumber:"",
                    interiorNumber:"",
                    postalCode:"",
                    colony:"",
                    betweenStreet:""
                },
                cartServices: [],
                branchBusiness:null,
                branch_business_id: 0,

                isLoading: false,
                fullPage: true,

                disabledSubmit: false,
                active: false,
                typeBranch: null,
                phoneNumber: '',
                date: null,
                time: null,
                times: [],
                closed: false,
                total: 0.00,
                granTotal: 0.00,
                fidelity: 0,

                //datepicker
                es: es,
                dateFormat: 'd/M/yyyy',
                disabledDates: {
                    to: new Date(Date.now() - 8640000),
                    days: [0],
                    dates: [new Date()]
                }
            };
        },

        beforeMount() {
            if(localStorage.typeAppointment){
                this.typeBranch = 'KN';
                this.typeAppointment = localStorage.typeAppointment;
                this.branch_business_id = localStorage.branch_business_id;
                if(this.typeAppointment === "INBRANCH"){
                    if(localStorage.branchBusiness){
                        this.branchBusiness = JSON.parse(localStorage.branchBusiness);
                        this.branch_business_id = this.branchBusiness.id;
                        this.appointment.address.name = this.branchBusiness.name;
                        this.appointment.address.street = this.branchBusiness.address;
                        if(this.branch_business_id === 1 || this.branch_business_id === 2 || this.branch_business_id === 3){
                            this.typeBranch = 'PARTNER';
                        }
                    }
                    this.showAddressCustomer = false;
                }else{
                    this.getAddressCustomer();
                    var _newDate = new Date();
                    _newDate.setDate(_newDate.getDate() + 2);
                    this.disabledDates = {
                        to: _newDate,
                        days: [0]
                    };
                    this.showAddressCustomer = true;
                }
            }

            // Si ya existen servicios agregados
            if(localStorage.cartServices){
                this.cartServices = JSON.parse(localStorage.cartServices);
                this.checkFidelity(this.cartServices);
                if(this.cartServices.length){
                    this.active = true;
                    this.getCustomer();
                }
            }
        },

        methods: {
            getCustomer:function(){
                axios.post('/getCustomer', {})
                    .then(response=>{
                        this.phoneNumber = response.data.customer.phoneNumber;
                    })
                    .catch(err=>{
                        this.phoneNumber = '';
                    });
            },

            getAddressCustomer:function(){
                axios.post('/getAddressCustomer', {})
                    .then(response=>{
                        this.allAddressCustomer = response.data;
                    })
                    .catch(err=>{
                        this.allAddressCustomer = [];
                    });
            },

            hideFormNewAddress:function(){
                this.showNewAddressCustomer = false;
            },

            showFormNewAddress:function(){
                this.showNewAddressCustomer = true;
            },

            saveAddress:function(){
                if(this.validateNewAddress()){
                    this.errorNewAddress = '';
                    axios.post('/storeAddress', {address:this.newAddress})
                        .then(response=>{
                            this.getAddressCustomer();
                            this.hideFormNewAddress();
                            this.appointment.address = response.data;
                            this.newAddress = {
                                name:"",
                                street:"",
                                outdoorNumber:"",
                                interiorNumber:"",
                                postalCode:"",
                                colony:"",
                                betweenStreet:""
                            };
                        })
                        .catch(err=>{});
                }else{
                    this.errorNewAddress = 'Los campos con (*) son requeridos';
                }
            },

            validateNewAddress:function(){
                if(this.newAddress.name === ""){
                    return false;
                }
                if(this.newAddress.street === ""){
                    return false;
                }
                if(this.newAddress.outdoorNumber === ""){
                    return false;
                }
                if(this.newAddress.postalCode === ""){
                    return false;
                }
                if(this.newAddress.colony === ""){
                    return false;
                }
                if(this.newAddress.betweenStreet === ""){
                    return false;
                }
                return true;
            },

            checkFidelity: function(pcartServices) {
                if(pcartServices.length){
                    axios.post('/checkServiceFidelity', {services: pcartServices})
                        .then(response=>{
                            this.cartServices = response.data;
                            this.setGranTotal();
                            if(this.cartServices <= 0){
                                this.active = false;
                            }
                        })
                        .catch(err=>{
                            this.cartServices = [];
                            this.active = false;
                        });
                }
            },

            getTimes: function() {
                if(this.cartServices.length && this.date){
                    this.time = null;
                    if(this.typeAppointment === "INBRANCH"){
                        axios.post('/getTimes', {branch_business_id: this.branch_business_id, services: this.cartServices, date: this.date})
                            .then(response=>{
                                this.times = [];
                                this.closed = false;
                                if(response.data == "closed"){
                                    this.times = [];
                                    this.closed = true;
                                }else{
                                    this.times = response.data;
                                }
                            })
                            .catch(err=>{
                                this.times = [];
                            })
                    }else{
                        this.times = [
                            {id: "10:00",   name: "10:00"},
                            {id: "10:30",   name: "10:30"},
                            {id: "11:00",   name: "11:00"},
                            {id: "11:30",   name: "11:30"},
                            {id: "12:00",   name: "12:00"},
                            {id: "12:30",   name: "12:30"},
                            {id: "13:00",   name: "13:00"},
                            {id: "13:30",   name: "13:30"},
                            {id: "14:00",   name: "14:00"},
                            {id: "14:30",   name: "14:30"},
                            {id: "15:00",   name: "15:00"},
                            {id: "15:30",   name: "15:30"},
                            {id: "16:00",   name: "16:00"},
                            {id: "16:30",   name: "16:30"},
                        ];
                    }
                }
            },

            appointmentStore: function() {
                if(this.phoneNumber != ''){
                    this.errorPhone = '';
                    this.isLoading = true;
                    axios.post('/Appointment/store', {typeBranch: this.typeBranch, typeAppointment: this.typeAppointment, phoneNumber: this.phoneNumber, address_id: this.appointment.address.id, branch_business_id: this.branch_business_id, services: this.cartServices, date: this.date, time: this.time})
                        .then(response=>{
                            if(this.typeBranch === "KN"){
                                 if(this.typeAppointment === "PREBOOKING"){
                                    window.location.href = '/Payment/'+ response.data.sale_id +'/success';
                                 }else{
                                    window.location.href = '/paypal/checkout/'+response.data.sale_id;
                                 }
                            }else{
                                 window.location.href = '/Payment/'+ response.data.sale_id +'/success';
                            }
                            localStorage.removeItem('cartServices');
                            this.isLoading = false;
                        })
                        .catch(err=>{
                            this.isLoading = false;
                        })

                }else{
                    this.errorPhone = 'Es necesario registrar un número de teléfono';
                }
            },

            removeService: function(index) {
                this.$delete(this.cartServices, index);
                if(this.cartServices.length <= 0){
                    this.active = false;
                    localStorage.removeItem('cartServices');
                }
                this.setGranTotal();
            },

            setGranTotal: function() {
                this.granTotal = 0;
                var _total_fidelity = 0;
                for(var item of this.cartServices){
                    this.granTotal += item.quantity * item.price;
                    _total_fidelity = parseFloat(_total_fidelity) + parseFloat(item.fidelity);
                }
                this.granTotal = parseFloat(this.granTotal) - parseFloat(_total_fidelity);
                this.fidelity = _total_fidelity;
                if(this.typeAppointment === "INBRANCH"){
                    this.total = this.granTotal * .50;
                }else{
                    this.total = (this.granTotal+120) * .70;
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
