<template>
    <div class="col-md-12">
        <!-- start branch -->
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-5">
                        <label for="sucursal">Sucursal</label>
                        <select class="custom-select" name="sucursal" @change="getServices(); setEmptyCart();" v-model="branch_business_id">
                            <option v-for="branch in branchesBusiness" :key="branch.id"
                                v-bind:value="branch.id">{{ branch.name }} ({{ branch.category }})</option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <div class="header-cart pull-right" style="padding-top: 10px;">
                            <a href="#">
                                <div class="cart-icon">
                                    <i class="ion-bag"></i>
                                    <span class="count-style">{{ cartServices.length }}</span>
                                </div>
                                <div class="cart-text">
                                    <span class="digit">Agenda</span>
                                    <span>$ {{ total.toFixed(2) }}</span>
                                </div>
                            </a>
                            <div v-if="cartServices.length" class="shopping-cart-content">
                                <ul>
                                    <li v-for="(service, index) in cartServices" :key="index" class="single-shopping-cart">
                                        <div class="shopping-cart-title">
                                            <h4><a href="#"> {{ service.name }} </a></h4>
                                            <span>${{ service.price }}</span>
                                        </div>
                                        <div class="shopping-cart-delete">
                                            <a v-on:click="removeServiceInCart(index, service)"><i class="ion ion-close"></i></a>
                                        </div>
                                    </li>
                                </ul>
                                <div class="shopping-cart-total">
                                    <h4>Total : <span class="shop-total">$ {{ total.toFixed(2) }}</span></h4>
                                </div>
                                <div class="shopping-cart-btn">
                                    <a href="/Appointment/index">Agendar</a>
                                </div>
                            </div>
                            <div v-else class="shopping-cart-content">
                                <ul>
                                    <li style="display:block;" class="cart-dropdown cart-empty-title">
                                        <h2 class="text-center" style="font-size: 16px;">Su carrito está vacío.</h2>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end branch -->

        <h3 class="text-center pt-5" style="font-size: 28px;">• MENÚ {{ branchBusiness ? branchBusiness.category : "" }} •</h3>

        <!-- start menu -->
        <div class="row pt-4" v-if="services">
            <div class="col-md-6">
                <ul>
                    <li v-if="services[1]">
                        <div class="clear10" style="height: 0;"></div>
                        <div class="bgMenu">
                            <div class="padding">
                                <div class="tituloMenu">platos fuertes</div>
                                <div class="abstractMenu">Cocinados con amor</div>
                            </div>
                        </div>

                        <div class="clear10"></div>
                        <div class="bgMenu">
                            <div class="paddingServicio">
                                <div class="conteSub">
                                    <div class="infoSub">
                                        manicures
                                        <div class="test">Esencial para tus manos</div>
                                    </div>
                                    <div class="clear"></div>
                                </div>

                                <div class="infoServicio" v-for="manicure in services[1]" :key="manicure.id">
                                    <div class="tituloServicio">{{ manicure.name }}</div>
                                    <div class="precioServicio">$ {{ manicure.price }} MXN.</div>
                                    <div class="clear10"></div>
                                    <div class="descripcionServicio">{{ manicure.description }}</div>
                                    <div class="clear5"></div>
                                    <div class="pull-right">
                                        <button class="btn btn-light btnCart" v-if="!manicure.add" v-on:click="addServiceToCart(manicure)"><i class="icon-handbag"></i> Reservar</button>
                                        <button class="btn btn-dark btnCart" v-else><i class="fa fa-check"></i> Añadido</button>
                                    </div>
                                    <div class="tiempoServicio">Tiempo de cocción aprox.:
                                        <span>{{ manicure.duration }} min</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </li>
                    <li v-if="services[2]">
                        <div class="clear10" style=""></div>
                        <div class="bgMenu">
                            <div class="padding">
                                <div class="tituloMenu">acompañamientos</div>
                                <div class="abstractMenu">Engrandece tu experiencia</div>
                            </div>
                        </div>

                        <div class="clear10"></div>
                        <div class="bgMenu">
                            <div class="paddingServicio">
                                <div class="conteSub">
                                    <div class="infoSub">
                                        pedicures
                                        <div class="test">Consintiendo tus pies</div>
                                    </div>
                                    <div class="clear"></div>
                                </div>

                                <!-- foreach aqui Pedicures -->
                                <div class="infoServicio" v-for="pedicure in services[2]" :key="pedicure.id">
                                    <div class="tituloServicio">{{ pedicure.name }}</div>
                                    <div class="precioServicio">$ {{ pedicure.price }} MXN.</div>
                                    <div class="clear10"></div>
                                    <div class="descripcionServicio">{{ pedicure.description }}</div>
                                    <div class="clear5"></div>
                                    <div class="pull-right">
                                        <button class="btn btn-light btnCart" v-if="!pedicure.add" v-on:click="addServiceToCart(pedicure)"><i class="icon-handbag"></i> Reservar</button>
                                        <button class="btn btn-dark btnCart" v-else><i class="fa fa-check"></i> Añadido</button>
                                    </div>
                                    <div class="tiempoServicio">Tiempo de cocción aprox.:
                                        <span>{{ pedicure.duration }} min</span></div>
                                </div>
                                <!-- fin foreach -->

                            </div>
                        </div>
                    </li>
                    <li v-if="services[4] || services[6]">
                        <div class="clear10" style=""></div>
                        <div class="bgMenu">
                            <div class="padding">
                                <div class="tituloMenu">postres</div>
                                <div class="abstractMenu">La vida es dulce</div>
                            </div>
                        </div>

                        <div class="clear10"></div>
                        <div class="bgMenu">
                            <div class="paddingServicio">
                                <div class="conteSub">
                                    <div class="infoSub">
                                        gel color
                                        <div class="test">Color duradero</div>
                                    </div>
                                    <div class="clear"></div>
                                </div>

                                <!--  gel manos -->
                                <div class="infoServicio" v-for="gelManos in services[4]" :key="gelManos.id">
                                    <div class="tituloServicio">{{ gelManos.name }}</div>
                                    <div class="precioServicio">$ {{ gelManos.price }} mxn.</div>
                                    <div class="clear10"></div>
                                    <div class="descripcionServicio">{{ gelManos.description }}</div>
                                    <div class="clear5"></div>
                                    <div class="pull-right">
                                        <button class="btn btn-light btnCart" v-if="!gelManos.add" v-on:click="addServiceToCart(gelManos);"><i class="icon-handbag"></i> Reservar</button>
                                        <button class="btn btn-dark btnCart" v-else><i class="fa fa-check"></i> Añadido</button>
                                    </div>
                                    <div class="tiempoServicio">Tiempo de cocción aprox.:
                                        <span>{{ gelManos.duration }} min</span></div>
                                </div>
                                <!-- gel pies -->
                                <div class="infoServicio" v-for="gelPies in services[6]" :key="gelPies.id">
                                    <div class="tituloServicio">{{ gelPies.name }}</div>
                                    <div class="precioServicio">$ {{ gelPies.price }} mxn.</div>
                                    <div class="clear10"></div>
                                    <div class="descripcionServicio">{{ gelPies.description }}</div>
                                    <div class="clear5"></div>
                                    <div class="pull-right">
                                        <button class="btn btn-light btnCart" v-if="!gelPies.add" v-on:click="addServiceToCart(gelPies)"><i class="icon-handbag"></i> Reservar</button>
                                        <button class="btn btn-dark btnCart" v-else><i class="fa fa-check"></i> Añadido</button>
                                    </div>
                                    <div class="tiempoServicio">Tiempo de cocción aprox.:
                                        <span>{{ gelPies.duration }} min</span></div>
                                </div>

                            </div>
                        </div>
                    </li>
                    <div class="clear"></div>
                </ul>
            </div>
            <div class="col-md-6">
                <ul>
                    <li v-if="services[3]">
                        <div class="clear10" style="height: 0;"></div>
                        <div class="bgMenu">
                            <div class="padding">
                                <div class="tituloMenu">extras</div>
                                <div class="abstractMenu">Agrega una pizca de felicidad</div>
                            </div>
                        </div>

                        <div class="clear10"></div>
                        <div class="bgMenu">
                            <div class="paddingServicio">
                                <div class="conteSub">
                                    <div class="infoSub">

                                        <div class="test"></div>
                                    </div>

                                    <div class="descripcionSub"></div>
                                    <div class="clear"></div>
                                </div>

                                <!-- foreach extras -->
                                <div class="infoServicio" v-for="extras in services[3]" :key="extras.id">
                                    <div class="tituloServicio">{{ extras.name }}</div>
                                    <div class="precioServicio">$ {{ extras.price }} mxn.</div>
                                    <div class="clear10"></div>
                                    <div class="descripcionServicio">{{ extras.description }}</div>
                                    <div class="clear5"></div>
                                    <div class="pull-right">
                                        <button class="btn btn-light btnCart" v-if="!extras.add" v-on:click="addServiceToCart(extras)"><i class="icon-handbag"></i> Reservar</button>
                                        <button class="btn btn-dark btnCart" v-else><i class="fa fa-check"></i> Añadido</button>
                                    </div>
                                    <div class="tiempoServicio">Tiempo de cocción aprox.:
                                        <span>{{ extras.duration }} min</span></div>
                                </div>
                                <!-- fin foreach -->

                            </div>
                        </div>
                    </li>
                    <li v-if="services[5]">
                        <div class="clear10" style=""></div>
                        <div class="bgMenu">
                            <div class="padding">
                                <div class="tituloMenu">especiales de temporada</div>
                                <div class="abstractMenu">Hechos para disfrutar</div>
                            </div>
                        </div>

                        <div class="clear10"></div>
                        <div class="bgMenu">
                            <div class="paddingServicio">
                                <div class="conteSub">
                                    <div class="infoSub">
                                        acrílico
                                        <div class="test"></div>
                                    </div>
                                    <div class="clear"></div>
                                </div>

                                <!-- foreach acrilico -->
                                <div class="infoServicio" v-for="acrilico in services[5]" :key="acrilico.id">
                                    <div class="tituloServicio">{{ acrilico.name }}</div>
                                    <div class="precioServicio">$ {{ acrilico.price }} mxn.</div>
                                    <div class="clear10"></div>
                                    <div class="descripcionServicio">{{ acrilico.description }}</div>
                                    <div class="clear5"></div>
                                    <div class="pull-right">
                                        <button class="btn btn-light btnCart" v-if="!acrilico.add" v-on:click="addServiceToCart(acrilico)"><i class="icon-handbag"></i> Reservar</button>
                                        <button class="btn btn-dark btnCart" v-else><i class="fa fa-check"></i> Añadido</button>
                                    </div>
                                    <div class="tiempoServicio">
                                        Tiempo de cocción aprox.:
                                        <span>{{ acrilico.duration }} min</span>
                                    </div>
                                </div>
                                <!-- fin foreach -->

                            </div>
                        </div>
                    </li>
                    <div class="clear"></div>
                </ul>
            </div>
        </div>
        <!-- Fin menu -->
    </div>
</template>

<script>
    export default {

        data: function() {
            return {
                typeAppointment: "INBRANCH",
                branch_business_id: null,
                branchBusiness: null,
                showSectionBranch: false,
                branchesBusiness: [],
                services: [],
                cartServices: [],
                total: 0.00
            };
        },

         beforeMount() {
            this.checkFilterPreselect();
            this.getBranches();
            this.getCartInLocalStorage();
        },

        methods: {
            checkFilterPreselect: function(){
                localStorage.typeAppointment = this.typeAppointment;

                if(localStorage.branch_business_id){
                    this.branch_business_id = localStorage.branch_business_id;
                }

                if(localStorage.branch_business_id && localStorage.branchBusiness){
                    this.branchBusiness = localStorage.branchBusiness;
                }
            },

            getBranches: function() {
                this.services = [];
                axios.post('/getBranches', {})
                    .then(response=>{
                        this.branchesBusiness = response.data;
                        if(this.branchesBusiness.length > 0){
                            this.branch_business_id = this.branchesBusiness[0].id;
                            this.setFilterToLocalStorage();
                            this.getServices();
                        }
                    })
                    .catch(err=>{})
            },

            getServices: function() {
                this.services = [];
                axios.post('/getServices', {typeAppointment: this.typeAppointment, branch_business_id: this.branch_business_id})
                    .then(response=>{
                        this.services = response.data;
                        this.setFilterToLocalStorage();
                    })
                    .catch(err=>{});
            },

            setFilterToLocalStorage: function() {
                if(this.branch_business_id > 0){
                    localStorage.branch_business_id = this.branch_business_id;
                    localStorage.branchBusiness = JSON.stringify(this.getBranchBusinessSelected(this.branch_business_id));
                }
            },

            getBranchBusinessSelected:function(pbranch_business_id){
                var _branchBusiness = this.branchesBusiness.find(item => item.id === pbranch_business_id);
                this.branchBusiness = _branchBusiness;
                return _branchBusiness;
            },

            addServiceToCart: function(service) {
                service.add = true;
                setTimeout(function () {
                    service.add = false;
                }, 1000);
                var _service = this.cartServices.find(item => item.id === service.id)
                if(_service){
                    // Ya existe
                    return;
                }
                service.quantity = 1;
                this.cartServices.push(service);
                this.setCartToLocalStorage();
            },

            removeServiceInCart: function(index, service) {
                this.$delete(this.cartServices, index);
                this.setCartToLocalStorage();
            },

            setEmptyCart: function(){
                this.cartServices = [];
                this.total = 0;
                this.setCartToLocalStorage();
            },

            setCartToLocalStorage: function() {
                let parsed = JSON.stringify(this.cartServices);
                localStorage.setItem('cartServices', parsed)
                this.getCartInLocalStorage();
            },

            getCartInLocalStorage: function() {
                if(localStorage.getItem('cartServices')) {
                    this.cartServices = JSON.parse(localStorage.getItem('cartServices'));
                    this.total = 0;
                    for(var _service of this.cartServices){
                        this.total = parseFloat(this.total) + parseFloat(_service.price);
                    }
                }
            },
        }
    }
</script>